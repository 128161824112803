.pageHeader-bees {
  background-image: url(../public/bees-alpha.png);
  background-repeat: no-repeat;
  background-size: auto 380px;
  background-position: left 100% top;
  background-blend-mode: luminosity;
}

.pageHeader-bees--light {
  background-image: url(../public/bees-light.png);
}
